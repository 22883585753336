import { useContext, PropsWithChildren } from "react";
import { redirectTo403 } from "@/utils/window";
import AppContext from "@/contexts/AppContext";

type AuthorityGuardProps = PropsWithChildren<{
  permission: string;
}>;

const AuthorityGuard = (props: AuthorityGuardProps) => {
  const { permission, children } = props;
  const { permissions } = useContext(AppContext);

  if (!permissions.dataPolicy[permission]) {
    redirectTo403();
    return;
  }

  return <>{children}</>;
};

export default AuthorityGuard;
