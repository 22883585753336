export const isUICExists = !!window.parent._CURRENT_ENV;

export const redirectTo401 = () => {
  if (isUICExists) {
    window.parent.location.href = `${window.parent.location.origin}/#/users/401`;
  } else {
    window.parent.location.href = "/#/users/401";
  }
};

export const redirectTo403 = () => {
  if (isUICExists) {
    window.parent.location.href = `${window.parent.location.origin}/#/users/403`;
  } else {
    window.parent.location.href = "/#/users/403";
  }
};

export const openWindow = (url, options = { target: "_blank" }) => {
  if (!url) {
    return;
  }

  const w = window.open("", options.target, "");
  if (w) {
    w.location = url;
    w.opener = null;
  }
};
